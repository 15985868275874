import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '@/pages/Home';
import RSVPNo from '@/pages/RSVPNo';
import RSVPYes from '@/pages/RSVPYes';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sorry" element={<RSVPNo />} />
        <Route path="/thank-you" element={<RSVPYes />} />
      </Routes>
    </Router>
  );
}

export default App;
import { MultiStepForm } from '@/components/MultiStepForm'; // Adjust the import path as necessary

function Home() {
  return (
    <div
      className="relative min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url('/Invite.png')` }}
    >
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-60"></div>
      
      <div className="relative z-10 flex flex-col items-center justify-center min-h-screen w-full p-4">
        {/* Form Container */}
        <div className="w-full max-w-lg bg-white/90 backdrop-blur-lg p-8 rounded-xl shadow-2xl">
          <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
            WELCOME!
          </h1>
          <MultiStepForm />
        </div>
      </div>
    </div>
  );
}

export default Home;

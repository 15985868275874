import { useFormContext, useFieldArray } from "react-hook-form";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function StepTwo() {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "emails",
  });

  return (
    <div className="bg-white rounded-lg p-4 max-w-lg mx-auto">
      <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">Great, just a few more details</h2>

      {/* Number of Adults and Children Section */}
      <div className="mb-4">
        <FormLabel className="text-gray-700 font-semibold">Number of Guests</FormLabel>
        <div className="flex gap-4 mt-2">
          <FormField
            control={control}
            name="numberOfAdults"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-gray-600">Adults</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    min={0}
                    placeholder="0"
                    {...field}
                    onChange={(e) => field.onChange(parseInt(e.target.value, 10) || 0)}
                    className="w-24 border-gray-300 rounded-lg p-2 focus:border-blue-500 focus:ring-blue-500"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="numberOfChildren"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-gray-600">Children</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    min={0}
                    placeholder="0"
                    {...field}
                    onChange={(e) => field.onChange(parseInt(e.target.value, 10) || 0)}
                    className="w-24 border-gray-300 rounded-lg p-2 focus:border-blue-500 focus:ring-blue-500"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      {/* Dietary Restrictions Field */}
      <FormField
        control={control}
        name="dietaryRestrictions"
        render={({ field }) => (
          <FormItem className="mb-4">
            <FormLabel className="text-gray-700 font-semibold">Dietary Restrictions</FormLabel>
            <FormControl>
              <Textarea {...field} className="w-full mt-1 border-gray-300 rounded-lg p-2 focus:border-blue-500 focus:ring-blue-500" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {/* Emails Field - Dynamic List */}
      <div className="mb-4">
        <FormLabel className="text-gray-700 font-semibold">Emails</FormLabel>
        {fields.map((item, index) => (
          <FormField
            key={item.id}
            control={control}
            name={`emails.${index}.email`}
            render={({ field }) => (
              <FormItem className="flex items-center space-x-2 mb-3">
                <FormControl>
                  <Input
                    type="email"
                    placeholder="example@domain.com"
                    {...field}
                    className="w-full border-gray-300 rounded-lg p-2 focus:border-blue-500 focus:ring-blue-500"
                    required={index === 0}
                  />
                </FormControl>
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className="flex items-center justify-center h-6 w-6 bg-white text-gray-600 border border-gray-300 rounded-full hover:bg-red-500 hover:text-white hover:border-red-500 transition-colors duration-200"
                  >
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                )}
                <FormMessage />
              </FormItem>
            )}
          />
        ))}
        <Button
          type="button"
          onClick={() => append({ email: "" })}
          className="mt-2 bg-blue-600 text-white font-medium py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-200"
        >
          Add Email
        </Button>
      </div>
    </div>
  );
}
